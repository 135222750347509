import React, { useState, useEffect } from 'react';
import { Title } from '@shared/ui';
/* eslint-disable react/jsx-no-duplicate-props */

import { Button, Form, Input, Select } from '@shared/ui';
import { Col, Row, DatePicker, Alert, InputNumber, Radio } from 'antd';
import { GetCategoriesList } from '@features/manage-materialcategories/model/services/GetCategoriesList';
import UploadPicturesWall from '@widgets/PicturesWall/UploadPicturesWall';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import SelectAddress from '@widgets/FormItems/SelectAddress';
import { TimePicker, Modal } from 'antd';
import { leadExtraFieldsToExport } from '@shared/const/leadExtraFields';
import { message, theme } from 'antd';
import { mobileNumberRequired } from '@shared/config/fieldValidatorSettings';
import { getTypeOfAddresses } from '@shared/api/all/const';
import dayjs from 'dayjs';
import { normalizeLeadTimes } from '@shared/lib/leadTimes';
import { CreatePublicLead } from '@features/list-lead/model/CreatePublicLead';
const { TextArea } = Input;

// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
   // Can not select days before today and today s
   return current && current < dayjs().startOf('day');
};
const disabledDateTime = () => ({
   disabledHours: () => [0, 1, 2, 3, 4, 5]
});

const CreateLeadPublic = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');
   const [form] = Form.useForm();
   const [categories, setCategories] = useState([]);
   const [typeOfAddresses, setTypeOfAddresses] = useState([]);
   // eslint-disable-next-line no-unused-vars

   const { token } = theme.useToken();

   const contentStyle = {
      padding: '20px',
      color: token.colorTextTertiary,
      backgroundColor: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      border: `1px dashed ${token.colorBorder}`,
      marginTop: 16
   };

   useEffect(() => {
      fetchTypeOfAddresses();
   }, []);

   const fetchTypeOfAddresses = async () => {
      getTypeOfAddresses().then((res) => {
         setTypeOfAddresses(res?.json?.data || []);
      });
   };

   useEffect(() => {
      GetCategoriesList({
         page: 1,
         limit: 1000,
         sort: 'priority',
         order: 'asc',
         status: 'active'
      }).then((res) => {
         setCategories(res.data);
      });
   }, []);

   const loadSuccessModal = () => {
      Modal.success({
         title: 'Заявка отправлена',
         content: 'В ближайшее время ею займутся наши менеджеры'
      });
   };

   const onFinish = (values) => {
      if (window.cantSubmitBecauseImageUploadingNow) {
         message.error('Картинки грузятся, подождите');
         return;
      }

      if (typeof values?.price === 'string') {
         if (!values.price.includes('.')) {
            values.price = values.price + '.00';
         } else {
            values.price = parseFloat(values.price).toFixed(1);
         }
      }

      if (values?.leadTimes?.length > 0) {
         const normalized = normalizeLeadTimes(values?.leadTimes, { leadTimes: [] });
         values.leadTimes = normalized;
      } else {
         message.error('Заполните даты вызова');
         return;
      }

      if (values.status) {
         values.status = {
            status: values.status || 'active',
            comment: values?.statusComment || ''
         };
      }

      console.log({ leadData: values, images: values?.images || [] });

      setIsLoading(true);

      CreatePublicLead({ leadData: values, images: values?.images || [] })
         .then(() => {
            form.resetFields();
            loadSuccessModal();
            setErrorMessage('');
         })
         .catch((e) => {
            const errMessage = e?.message || 'Возникла ошибка при сохранении';
            setErrorMessage(errMessage);
            message.error(errMessage);
         })
         .finally(() => setIsLoading(false));
   };

   const setImagesIds = (arr) => {
      form.setFieldValue('images', arr);
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const suffixSelector = <Form.Item noStyle>кг</Form.Item>;
   const suffixSelectorPrice = <Form.Item noStyle>руб</Form.Item>;

   const modifiedInitialValues = {
      price: '0,0',
      priceType: 'unit',
      statusComment: '',
      leadTimes: [],
      finishDate: null,
      distance: 0
   };

   return (
      <div style={{ margin: '20px', maxWidth: '1600px' }}>
         {' '}
         <Title>Создать заявку</Title>
         <Row gutter={24}>
            {' '}
            <Col span={24}>
               {' '}
               <div style={contentStyle}>
                  <div>
                     <Form
                        style={{
                           maxWidth: '100%',
                           minWidth: 320
                        }}
                        form={form}
                        initialValues={modifiedInitialValues}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        hideRequiredMark
                        layout="vertical">
                        <Row gutter={16}>
                           <Col span={24}>
                              <Form.Item
                                 name="materialCategoryId"
                                 label="Категория вторсырья"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Выберите категории'
                                    }
                                 ]}>
                                 <Select
                                    allowClear
                                    style={{
                                       width: '100%'
                                    }}
                                    showSearch
                                    optionFilterProp="name"
                                    filterOption={(input, option) =>
                                       (option?.name ?? '')
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                    }
                                    placeholder="Выберите тип сырься"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Поле не может быть пустым'
                                       }
                                    ]}
                                    fieldNames={{
                                       label: 'name',
                                       value: 'id'
                                    }}
                                    options={categories}
                                 />
                              </Form.Item>

                              <Row gutter={24}>
                                 {' '}
                                 <Col span={8}>
                                    <Form.Item name="capacity" label="Вес">
                                       <Input
                                          addonAfter={suffixSelector}
                                          style={{
                                             width: '100%'
                                          }}
                                          type="number"
                                          placeholder="Введите вес"
                                       />
                                    </Form.Item>
                                 </Col>
                                 <Col span={8}>
                                    <Form.Item
                                       label="Стоимость"
                                       name="priceType"
                                       defaultValue="unit">
                                       <Radio.Group buttonStyle="solid">
                                          <Radio.Button value="unit">
                                             За кг
                                          </Radio.Button>
                                          <Radio.Button value="total">
                                             Общая
                                          </Radio.Button>
                                       </Radio.Group>
                                    </Form.Item>
                                 </Col>
                                 <Col span={8}>
                                    <Form.Item name="price" label="Цену">
                                       <InputNumber
                                          addonAfter={suffixSelectorPrice}
                                          style={{
                                             width: '100%'
                                          }}
                                          defaultValue="0.0"
                                          type="number"
                                          placeholder="Введите стоимость"
                                          min="0"
                                          step="0.1"
                                          stringMode
                                          precision={1}
                                          decimalSeparator="."
                                       />
                                    </Form.Item>
                                 </Col>
                              </Row>
                              <Form.Item name="images" label="Загрузите картинку">
                                 <UploadPicturesWall
                                    maxCount={10}
                                    images={[]}
                                    setImagesIds={setImagesIds}
                                 />
                              </Form.Item>
                              <Form.Item name="comment" label="Комментарий">
                                 <TextArea
                                    rows={2}
                                    placeholder="Можете указать, номер подъезда, домофон, переулок"
                                    maxLength={500}
                                 />
                              </Form.Item>
                           </Col>
                        </Row>

                        <Row gutter={24}>
                           <Col span={24}>
                              <Form.Item
                                 name={'typeOfAddress'}
                                 label="Место вывоза"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Укажите место вывоза'
                                    }
                                 ]}>
                                 <Select
                                    style={{
                                       width: '100%'
                                    }}
                                    maxCount={1}
                                    placeholder="Выберите место вывоза"
                                    options={typeOfAddresses.map((e) => ({
                                       value: e.value,
                                       label: e.title
                                    }))}
                                 />
                              </Form.Item>

                              <Form.Item
                                 label="Полный адрес (город, улица, номер дома/квартиры)"
                                 name="address"
                                 rules={[
                                    { required: true, message: 'Укажите адрес' }
                                 ]}>
                                 <SelectAddress allowClear />
                              </Form.Item>

                              <Form.Item
                                 name="addressComment"
                                 label="Дополнительно про адрес">
                                 <TextArea
                                    rows={2}
                                    placeholder="Максимум 500 символов"
                                    maxLength={500}
                                 />
                              </Form.Item>

                              {Object.values(leadExtraFieldsToExport).map((e) => (
                                 <Form.Item
                                    label={e.label}
                                    name={e.value}
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Поле пропущено'
                                       }
                                    ]}
                                    key={e.value + e.label + 'form'}>
                                    {e.type === 'boolean' ? (
                                       <Radio.Group name={e.value}>
                                          <Radio value={true}>Да</Radio>
                                          <Radio value={false}>Нет</Radio>
                                       </Radio.Group>
                                    ) : null}

                                    {e.type === 'integer' ? (
                                       <Input
                                          style={{
                                             width: '160px'
                                          }}
                                          placeholder="в метрах"
                                          type="number"
                                       />
                                    ) : null}
                                 </Form.Item>
                              ))}
                           </Col>
                        </Row>

                        <Row gutter={16}>
                           <Col span={12}>
                              <Form.Item
                                 name="firstName"
                                 label="Имя"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Поле пропущено'
                                    }
                                 ]}>
                                 <Input
                                    placeholder="Имя"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Поле пропущено'
                                       }
                                    ]}
                                 />
                              </Form.Item>
                              <Form.Item
                                 name="lastName"
                                 label="Фамилия"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Поле пропущено'
                                    }
                                 ]}>
                                 <Input placeholder="Фамилия" />
                              </Form.Item>
                           </Col>
                           <Col span={12}>
                              <Form.Item
                                 name="email"
                                 label="E-mail"
                                 rules={[
                                    {
                                       type: 'email',
                                       message: 'Не похоже на почту!'
                                    },
                                    {
                                       required: true,
                                       message: 'Поле пропущено'
                                    }
                                 ]}>
                                 <Input placeholder="Почта" />
                              </Form.Item>
                              <Form.Item
                                 name="mobileNumber"
                                 label="Телефон"
                                 rules={mobileNumberRequired}>
                                 <Input
                                    addonBefore={<Form.Item noStyle>+7</Form.Item>}
                                    style={{
                                       width: '100%'
                                    }}
                                    placeholder="Введите телефон"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item label="Даты вывоза">
                                 <Form.List name="leadTimes">
                                    {(fields, { add, remove }) => (
                                       <>
                                          {fields.map(
                                             ({ key, name, ...restField }) => (
                                                <Row
                                                   gutter={24}
                                                   key={`leadTimes${key}`}>
                                                   <Col span={24}>
                                                      <Form.Item
                                                         {...restField}
                                                         name={[
                                                            name,
                                                            'fromTimestamp'
                                                         ]}
                                                         rules={[
                                                            {
                                                               required: true,
                                                               message:
                                                                  'Укажите дату вывоза'
                                                            }
                                                         ]}>
                                                         <DatePicker
                                                            style={{
                                                               width: '100%'
                                                            }}
                                                            format="DD-MM-YYYY"
                                                            disabledDate={
                                                               disabledDate
                                                            }
                                                            disabledTime={
                                                               disabledDateTime
                                                            }
                                                         />
                                                      </Form.Item>

                                                      <Form.Item
                                                         {...restField}
                                                         name={[name, 'timeArr']}
                                                         rules={[
                                                            {
                                                               required: true,
                                                               message:
                                                                  'Укажите дату вывоза'
                                                            }
                                                         ]}>
                                                         <TimePicker.RangePicker
                                                            format="HH:mm"
                                                            minuteStep="5"
                                                            showNow
                                                            style={{
                                                               width: '100%'
                                                            }}
                                                         />
                                                      </Form.Item>
                                                   </Col>

                                                   <Col span={24}>
                                                      <Button
                                                         style={{
                                                            float: 'right',
                                                            marginBottom: '20px'
                                                         }}
                                                         danger
                                                         type="primary"
                                                         icon={
                                                            <MinusCircleOutlined />
                                                         }
                                                         onClick={() =>
                                                            remove(name)
                                                         }>
                                                         Удалить время
                                                      </Button>
                                                   </Col>
                                                </Row>
                                             )
                                          )}

                                          <Row gutter={24}>
                                             <Col span={24}>
                                                <Form.Item>
                                                   <Button
                                                      type="dashed"
                                                      onClick={() => add()}
                                                      block
                                                      icon={<PlusOutlined />}>
                                                      Добавить
                                                   </Button>
                                                </Form.Item>
                                             </Col>
                                          </Row>
                                       </>
                                    )}
                                 </Form.List>
                              </Form.Item>{' '}
                           </Col>{' '}
                           <Row gutter={[16, 24]}>
                              {errorMessage && (
                                 <Col className="gutter-row" span={24}>
                                    <Alert
                                       message={errorMessage.replace('Error: ', '')}
                                       type="error"
                                       style={{ marginBottom: '20px' }}
                                       showIcon
                                    />
                                 </Col>
                              )}
                           </Row>
                           <Col className="gutter-row" span={24}>
                              <Form.Item>
                                 <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}>
                                    Создать
                                 </Button>
                              </Form.Item>
                           </Col>
                        </Row>
                     </Form>{' '}
                  </div>
               </div>{' '}
            </Col>
            {/* <Col span={8}>
               <div style={{ paddingLeft: '10px', paddingTop: '15px' }}>
                  {current < steps.length - 1 && (
                     <Button type="primary" onClick={() => next()} size="large">
                        Далее
                     </Button>
                  )}
                  {current === steps.length - 1 && (
                     <Button
                        size="large"
                        type="primary"
                        onClick={() => message.success('Processing complete!')}>
                        Создать заявку
                     </Button>
                  )}
                  {current > 0 && (
                     <Button
                        size="large"
                        style={{
                           margin: '0 8px'
                        }}
                        onClick={() => prev()}>
                        Назад
                     </Button>
                  )}
               </div>
               <div style={{ marginTop: '40px', opacity: 0.6 }}>
                  <Steps
                     size="small"
                     direction="vertical"
                     current={current}
                     items={items}
                  />
               </div>
            </Col> */}
         </Row>
      </div>
   );
};

export default CreateLeadPublic;
